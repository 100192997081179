import PropTypes from 'prop-types';
import ColumnsWrapper from 'libs/storyblok/content/Modules/ColumnsWrapper';
import { columnsHeadingFontKeys } from 'config/branding/columns-header-font-keys';
import CmsDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock';
import { transformButton } from '@activebrands/core-web/libs/storyblok/utils/transform-buttons';
import transformHeading from '@activebrands/core-web/libs/storyblok/utils/transform-heading';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import getPositionStyles from '@activebrands/core-web/utils/style-functions/get-position-styles';
import TopPaginationSlider from 'components/Header/Navigation/TopPaginationSlider';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';
import { getCollapsibleColumnStyles } from './collapsible-styles';

const ThreeColumnsModule = ({ content = {}, isGrid = false }) => {
    const [css] = useStyletron();
    const {
        button = {},
        columns = [],
        heading = [],
        headingStyle = 'md',
        largeContentDesktopPosition = 'center',
        verticalAlignment,
    } = content;

    const headingSettings = transformHeading({
        type: heading?.[0]?.type,
        defaultType: 'h3',
    });

    const columnsLayoutPositionStyles = getPositionStyles({ contentVerticalAlignment: verticalAlignment });

    const transformedButton = button?.[0] ? transformButton(button?.[0]) : {};

    const largeContentStyle = {
        center: {
            0: { gridColumn: '1 / span 3', aspectRatio: '3 / 4' },
            1: { gridColumn: '4 / span 6' },
            2: { gridColumn: '10 / span 3', aspectRatio: '3 / 4' },
        },
        left: {
            0: { gridColumn: '1 / span 6' },
            1: { gridColumn: '7 / span 3', aspectRatio: '3 / 4' },
            2: { gridColumn: '10 / span 3', aspectRatio: '3 / 4' },
        },
        right: {
            0: { gridColumn: '1 / span 3', aspectRatio: '3 / 4' },
            1: { gridColumn: '4 / span 3', aspectRatio: '3 / 4' },
            2: { gridColumn: '7 / span 6' },
        },
    };

    return (
        <section className={css({ marginBottom: isGrid ? 0 : 'var(--margin-bottom-module)' })}>
            <div
                className={css({
                    alignItems: ['flex-start', null, 'center'],
                    display: ['none', null, null, null, 'flex'],
                    gap: '16px',
                    justifyContent: 'space-between',
                    marginBottom: '24px',
                    flexDirection: ['column', null, 'row'],
                    padding: '0 var(--margin-content)',
                    ...headingStyle,
                })}
            >
                {heading?.length > 0 && (
                    <Heading
                        $style={{ marginRight: 'auto' }}
                        as={headingSettings.type}
                        fontKeys={columnsHeadingFontKeys[headingStyle]}
                    >
                        {heading?.[0]?.text}
                    </Heading>
                )}
                {transformedButton.label && transformedButton.url && (
                    <ThemeButton
                        $style={{ marginTop: 'auto' }}
                        as={Link}
                        rel={transformedButton.rel}
                        theme={transformedButton.theme}
                        to={transformedButton.url}
                    >
                        {transformedButton.label}
                    </ThemeButton>
                )}
            </div>
            <div
                className={css({
                    ...columnsLayoutPositionStyles,
                    display: ['none', null, null, null, 'grid'],
                    gridTemplateColumns: 'repeat(12, 1fr)',
                    columnGap: '16px',
                    padding: '0 var(--margin-content)',
                })}
            >
                {columns.map((column, i) => {
                    return (
                        <div
                            key={i}
                            className={css({
                                width: '100%',
                                height: '100%',
                                borderRadius: 'var(--border-radius-module)',
                                overflow: 'hidden',
                                ...largeContentStyle?.[
                                    largeContentDesktopPosition.length > 0 ? largeContentDesktopPosition : 'center'
                                ]?.[i],
                                ...getCollapsibleColumnStyles(column.component),
                            })}
                        >
                            <CmsDataTransformerBlock isThreeColumns block={column} />
                        </div>
                    );
                })}
            </div>
            <TopPaginationSlider
                isDraggable
                $paginationStyle={{ display: 'none' }}
                $paginationWrapperStyle={{ display: 'none' }}
                // as the child component receiving these styles is potentially overflowing the x-axis, justify-content will not work
                $sliderStyle={{ ...columnsLayoutPositionStyles, justifyContent: 'auto' }}
                $style={{ display: ['none', null, 'block', null, 'none'] }}
                button={transformedButton}
                heading={heading?.length > 0 && heading?.[0]?.text}
                headingFontKeys={columnsHeadingFontKeys[headingStyle]}
                headingStyle={{ padding: '  0 var(--margin-content)' }}
                headingType={headingSettings.type}
                slidesPerView={[1.5, null, 2.5, null, 4]}
                spaceBetween={['12px', null, null, '16px']}
            >
                {columns.map((column, i) => (
                    <div key={column._uid}>
                        <ColumnsWrapper
                            $style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: 'var(--border-radius-module)',
                                overflow: 'hidden',
                                ...getCollapsibleColumnStyles(column.component),
                            }}
                            component={column.component}
                            numberOfItems={columns.length}
                            position={i}
                        >
                            <CmsDataTransformerBlock isThreeColumns block={column} />
                        </ColumnsWrapper>
                    </div>
                ))}
            </TopPaginationSlider>
            <div
                className={css({
                    display: ['flex', null, 'none'],
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '0 var(--margin-content)',
                })}
            >
                <div
                    className={css({
                        alignItems: 'flex-start',
                        display: 'flex',
                        gap: '24px',
                        justifyContent: 'space-between',
                        marginBottom: '24px',
                        flexDirection: 'column',
                        ...columnsHeadingFontKeys[headingStyle],
                    })}
                >
                    {heading?.length > 0 && (
                        <Heading
                            $style={{ marginRight: 'auto' }}
                            as={headingSettings.type}
                            fontKeys={columnsHeadingFontKeys[headingStyle]}
                        >
                            {heading?.[0]?.text}
                        </Heading>
                    )}
                    {transformedButton.label && transformedButton.url && (
                        <ThemeButton
                            $style={{ marginTop: 'auto' }}
                            as={Link}
                            rel={transformedButton.rel}
                            theme={transformedButton.theme}
                            to={transformedButton.url}
                        >
                            {transformedButton.label}
                        </ThemeButton>
                    )}
                </div>
                {columns.map((column, i) => (
                    <div key={column._uid}>
                        <ColumnsWrapper
                            $style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: 'var(--border-radius-module)',
                                overflow: 'hidden',
                                ...getCollapsibleColumnStyles(column.component),
                            }}
                            component={column.component}
                            numberOfItems={columns.length}
                            position={i}
                            verticalAlignment={verticalAlignment}
                        >
                            <CmsDataTransformerBlock isThreeColumns block={column} />
                        </ColumnsWrapper>
                    </div>
                ))}
            </div>
        </section>
    );
};

ThreeColumnsModule.propTypes = {
    content: PropTypes.object,
    isGrid: PropTypes.bool,
};

export default ThreeColumnsModule;
